import React, { memo, useCallback, useEffect, useState } from 'react'
import { useRoutes } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import routes from '@/router'
import ConnectIcon from '@/assets/common/connect.png'
import { injected } from '@/web3/connectors'
import { changeNetwork } from '@/web3'
import useAppStore from '@/store/app'
import { getQueryString } from '@/utils'
import { Mask } from 'antd-mobile'
import { CIRIconSvg } from '@/views/Home/icon'

const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID)
const Layout: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>('')
  const { active, activate, deactivate, account } = useWeb3React()
  const { t, i18n } = useTranslation()
  const { appInit, isBind, isLogin } = useAppStore()
  const connectWallet = async () => {
    await activate(
      injected,
      (err) => {
        console.log(err)
      },
      false,
    )
  }
  useEffect(() => {
    if (active && account) {
      window.localStorage.setItem('address', account as string)
      window.localStorage.setItem('i18nextLng', i18n.language)
      if (inputValue) {
        appInit(account as string, inputValue, t, deactivate)
      } else {
        const code = getQueryString('code')
        appInit(account as string, code, t, deactivate)
      }
    }
  }, [active, account])

  useEffect(() => {
    if (active && isLogin) {
      changeNetwork(CHAIN_ID)
    }
  }, [active, isLogin])
  const bind = () => {
    if (inputValue === '') {
      toast.error(t('qingShuRuTuiJianMa'))
      return
    }
    connectWallet()
  }
  return (
    <div className={'relative'}>
      <Mask visible={!isBind}>
        <div className={'bg-modalBg bg-default w-[333px] h-fit mx-auto mt-[40%]'}>
          <div className={'p-[20px] box-border'}>
            <div className={'flex justify-between items-center'}>
              <div className={'flex items-center text-white'}>
                <CIRIconSvg />
                <span className={'ml-1 text-[20px]'}>{t('bangDing')}</span>
              </div>
            </div>
            <div className={'mt-[10px] border border-1 border-white rounded p-[12px] box-border'}>
              <input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder={t('qingShuRuTuiJianMa') as string}
                className={'w-full h-full text-white text-[14px]'}
              />
            </div>
            <div
              onClick={bind}
              className={
                'text-white bg-connectWallet w-full text-[16px] flex justify-center items-center h-[56px] my-[20px]'
              }
            >
              {t('queDing')}
            </div>
          </div>
        </div>
      </Mask>
      <Toaster />
      <div className={`${!active ? 'pb-[86px]' : ''}`}>{useRoutes(routes)}</div>
      {!active && (
        <div
          className={
            'fixed bottom-0 left-0 right-0 h-[86px] w-screen bg-black px-[24px] py-[16px] box-border'
          }
        >
          <button
            onClick={connectWallet}
            className={
              'text-white bg-connectWallet bg-default w-full h-full text-[16px] flex justify-center items-center'
            }
          >
            <img src={ConnectIcon} className={'w-[19px] h-[19px] mr-[10px]'} alt='' />
            {t('lianJieQianBao')}
          </button>
        </div>
      )}
    </div>
  )
}

export default memo(Layout)
