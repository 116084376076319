import request from './index'
import { Response, ListResponse } from './type'
import {
  AssetsResponse,
  BannersRequest,
  BannersResponse,
  InviteDetailResponse,
  InviteHistoryRequest,
  InviteHistoryResponse,
  LoginRequest,
  LoginResponse,
  MinerDetailRequest,
  MinerDetailResponse,
  MinerHistoryRequest,
  MinerHistoryResponse,
  OverViewResponse,
  ComputeUSDTRequest,
  ComputeUSDTResponse,
  PledgeRequest,
  PledgeResponse,
  OrderStatusRequest,
  OrderStatusResponse,
  NodeDetailResponse,
  AssetsDetailResponse,
  AssetsDetailRequest,
  MiningMachineResponse,
  NextLevelResponse,
  RechargeRequest,
  RechargeResponse,
  WithdrawRequest,
  WithdrawResponse,
  WithdrawListResponse,
  WithdrawListRequest,
  AssetsDetailListRequest,
  AssetsDetailListResponse,
  NewsResponse,
  NewsRequest,
  NodeLevelResponse,
  withdrawResponse,
} from '@/request/api.type'

// 登录
export function apiLogin<T>(params: LoginRequest): Promise<Response<LoginResponse>> {
  return request.post({
    url: '/user/login/register',
    params,
  })
}

export function apiBanners<T>(): Promise<Response<ListResponse<BannersResponse>>> {
  return request.post({
    url: '/news/news/ad',
  })
}
export function apiOverview<T>(): Promise<Response<OverViewResponse>> {
  return request.post({
    url: '/Invest/Invest_Pledge/overview',
  })
}
export function apiMinerDetail<T>(
  params: MinerDetailRequest,
): Promise<Response<MinerDetailResponse>> {
  return request.post({
    url: '/Invest/Invest_Pledge/MinerDetail',
    params,
  })
}
// 矿机详情
export function apiNewMiner<T>(params: MinerDetailRequest): Promise<Response<MinerDetailResponse>> {
  return request.post({
    url: '/Invest/Invest_Pledge/info',
    params,
  })
}

export function apiHomeAssets<T>(): Promise<Response<AssetsResponse>> {
  return request.post({
    url: '/User/User/assets',
  })
}
export function apiMinerHistory<T>(
  params: MinerHistoryRequest,
): Promise<Response<ListResponse<MinerHistoryResponse>>> {
  return request.post({
    url: '/Invest/Invest_Pledge/getOrderList',
    params,
  })
}

export function apiInviteData<T>(): Promise<Response<InviteDetailResponse>> {
  return request.post({
    url: '/user/Team/index',
  })
}
export function apiInviteHistory<T>(
  params: InviteHistoryRequest,
): Promise<Response<ListResponse<InviteHistoryResponse>>> {
  return request.post({
    url: '/user/Team/lists',
    params,
  })
}

export function apiComputeUsdt<T>(
  params: ComputeUSDTRequest,
): Promise<Response<ComputeUSDTResponse>> {
  return request.post({
    url: '/Invest/Invest_Pledge/trans',
    params,
  })
}

export function apiPledgeClick<T>(params: PledgeRequest): Promise<Response<PledgeResponse>> {
  return request.post({
    url: '/Invest/Invest_Pledge/orderCreate',
    params,
  })
}

export function apiGetOrderStatus<T>(
  params: OrderStatusRequest,
): Promise<Response<OrderStatusResponse>> {
  return request.post({
    url: '/Invest/Invest_Pledge/orderStatus',
    params,
  })
}

export function apiGetNodeDetail<T>(params: {
  page: number
  limit: number
}): Promise<Response<NodeDetailResponse>> {
  return request.post({
    url: '/Invest/Job/detail',
    params,
  })
}

export function apiGetAssetsDetail<T>(
  params: AssetsDetailRequest,
): Promise<Response<AssetsDetailResponse>> {
  return request.post({
    url: '/User/User/money_type_info',
    params,
  })
}

// 临时 TODO
export function apiMinerList<T>(): Promise<Response<MiningMachineResponse>> {
  return request.post({
    url: '/Invest/Invest_Pledge/getMinerList',
  })
}

// 下一等级
export function apiNextLevel<T>(): Promise<Response<NextLevelResponse>> {
  return request.post({
    url: '/Invest/Invest_Pledge/group',
  })
}

export function apiRecharge<T>(params: RechargeRequest): Promise<Response<RechargeResponse>> {
  return request.post({
    url: '/user/Recharge/auto_recharge',
    params,
  })
}
export function apiWithdraw<T>(params: WithdrawRequest): Promise<Response<WithdrawResponse[]>> {
  return request.post({
    url: '/user/Withdraw/atm',
    params,
  })
}

// 提现明细
export function apiWithdrawList<T>(
  params: WithdrawListRequest,
): Promise<Response<ListResponse<withdrawResponse[]>>> {
  return request.post({
    url: '/user/Withdraw/withdrawllog',
    params,
  })
}

// 资金明细
export function apiAssetsDetailList<T>(
  params: AssetsDetailListRequest,
): Promise<Response<ListResponse<AssetsDetailListResponse[]>>> {
  return request.post({
    url: '/user/user/money_log',
    params,
  })
}

// 公告列表
export function apiGetAnnouncement<T>(
  params: NewsRequest,
): Promise<Response<ListResponse<NewsResponse>>> {
  return request.post({
    url: '/news/news/index',
    params,
  })
}

export function apiNodeLevel<T>(): Promise<Response<NodeLevelResponse[]>> {
  return request.post({
    url: '/Invest/Invest_Pledge/node',
  })
}

export function apiNodeApply<T>(params: { job_id: number }): Promise<Response> {
  return request.post({
    url: '/Invest/Job/orderCreate',
    params,
  })
}
