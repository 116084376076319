import React, { lazy } from 'react'
import type { RouteObject } from 'react-router-dom'

const Home = lazy(() => import('@/views/Home'))
const Fuel = lazy(() => import('@/views/Fuel'))
const Invite = lazy(() => import('@/views/Invite'))
const Node = lazy(() => import('@/views/Node'))
const Earning = lazy(() => import('@/views/Earning'))
const EarningRecord = lazy(() => import('@/views/Earning/record'))
const EarningHistory = lazy(() => import('@/views/Earning/history'))
const Announcement = lazy(() => import('@/views/Announcement'))
const NoFound = lazy(() => import('@/views/NoFound'))
const routes: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/fuel',
    element: <Fuel />,
  },
  {
    path: '/invite',
    element: <Invite />,
  },
  {
    path: '/node',
    element: <Node />,
  },
  {
    path: '/earnings',
    element: <Earning />,
  },
  {
    path: '/earnings/record',
    element: <EarningRecord />,
  },
  {
    path: '/earnings/history',
    element: <EarningHistory />,
  },
  {
    path: '/announcement',
    element: <Announcement />,
  },
  {
    path: '*',
    element: <NoFound />,
  },
]

export default routes
