import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
// 语言包
import hk from './hk.json'
import cn from './cn.json'
import en from './en.json'
import ko from './ko.json'
import vi from './vi.json'
import es from './es.json'
import ja from './ja.json'
import ru from './ru.json'
i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: navigator.language,
    fallbackLng: 'zh-HK',
    resources: {
      // 'zh-CN': {
      //   translation: cn,
      // },
      'zh-HK': {
        translation: hk,
      },
      'en-US': {
        translation: en,
      },
      'ko-KR': {
        translation: ko,
      },
      'vi-VN': {
        translation: vi,
      },
      'es-ES': {
        translation: es,
      },
      'ja-JP': {
        translation: ja,
      },
      'ru-RU': {
        translation: ru,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    detection: {
      caches: ['localStorage', 'sessionStorage', 'cookie'],
    },
  })
