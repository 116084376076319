import { create } from 'zustand'
import * as api from '@/request/api'
import toast from 'react-hot-toast'
import { LoginResponse } from '@/request/api.type'

interface AppState {
  token: string
  address: string
  isLogin: boolean
  isBind: boolean
  userInfo: LoginResponse | null
  appInit: (address: string, code: string, t: any, deactivate: any) => Promise<void>
}

const useAppStore = create<AppState>()((set) => ({
  token: '',
  address: '',
  isLogin: false,
  isBind: true,
  userInfo: null,
  appInit: async (address, code, t, deactivate) => {
    try {
      set({ address: address })
      const res = await api.apiLogin({
        referee: code,
      })
      set({ isLogin: true })
      set({ isBind: true })
      set({ userInfo: res.data })
      window.localStorage.setItem('token', res.data.token)
      toast.success(t('dengLuChengGong'))
    } catch (err: any) {
      toast.error(err.msg)
      if (err.code === -2) {
        set({ isBind: false })
      }
      set({ isLogin: false })
      deactivate()
      window.localStorage.clear()
    }
  },
}))

export default useAppStore
