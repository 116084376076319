import fuel from '@/assets/menu/fuel.svg'
import node from '@/assets/menu/node.svg'
import earnings from '@/assets/menu/earnings.svg'
import invite from '@/assets/menu/invite.svg'
export const PAY_URL = [
  '/Invest/Invest_Pledge/orderCreate',
  '/Invest/Invest_Pledge/orderCreate2',
  '/Invest/Invest_Pledge/orderCreate3',
]
export const NETWORK_WAIT = 500

export const menuConfig = [
  {
    id: 1,
    name: 'ranLiao',
    url: fuel,
    path: '/fuel',
  },
  {
    id: 2,
    name: 'jieDian',
    url: node,
    path: '/node',
  },
  {
    id: 3,
    name: 'shouYi',
    url: earnings,
    path: '/earnings',
  },
  {
    id: 4,
    name: 'yaoQing',
    url: invite,
    path: '/invite',
  },
]
